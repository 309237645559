import * as React from "react";
import {Link} from "gatsby";
import Nav from "./nav/nav";
import {StaticImage} from "gatsby-plugin-image";

import * as styles from "./header.module.scss";
import classNames from "classnames";

const Header = ({light}) => {
  const lightLogo = "../../images/D_light.png";
  const darkLogo = "../../images/D_dark.png"

  return (
    <header
      className={classNames({
        [styles.header]: true,
        [styles.light]: light,
      })}
    >

    <div className={styles.flexContainer}>
        <Link to="/">
          {light ?
            <StaticImage src={lightLogo}
                         alt="Logo"
                         className={styles.logo}
                         backgroundColor={"transparent"}
                         objectFit={"contain"}
            />
            :
            <StaticImage src={darkLogo}
                         alt="Logo"
                         className={styles.logo}
                         backgroundColor={"transparent"}
                         objectFit={"contain"}
            />
          }
        </Link>

        <Nav light={light} />
      </div>
    </header>
  );
};

Header.defaultProps = {
  light: false
}

export default Header;
