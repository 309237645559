import * as React from 'react';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import {Link} from "gatsby";
import {navItems} from "../../../config";

import * as styles from './nav.module.scss';

const Nav = ({light}) => {
  const [menuOpened, setMenuOpened] = useState(false);

  const onLinkClick = () => {
    setMenuOpened(false);
  };

  useEffect(() => {
    if (menuOpened) {
      document?.body?.classList?.add('no-scroll');
    } else {
      document?.body?.classList?.remove('no-scroll');
    }
  }, [menuOpened]);

  return (
    <nav
      className={classNames({
        [styles.nav]: true,
        [styles.menuOpened]: menuOpened,
        [styles.light]: light
      })}
    >
      <button
        aria-label="Menu"
        aria-controls="menu-list"
        className={classNames({
          hamburger: true,
          'hamburger--spin': true,
          'is-active': menuOpened,
          [styles.menuToggle]: true,
        })}
        onClick={() => setMenuOpened(!menuOpened)}
      >
				<span className="hamburger-box">
					<span className={`${styles.hamburgerInner} hamburger-inner`} />
				</span>
      </button>

      <div className={styles.menuList} id="menu-list">
        <ul className={styles.flexContainer}>
          {navItems.map(item => (
            <li key={item.title}
                className={classNames({
                  [styles.menuItem]: true,
                  [styles.hasSubmenu]: item.subItems !== null
                })}
            >
              {item.subItems === null ? (
                <Link to={item?.url} onClick={() => onLinkClick()}>
                  {item.title}
                </Link>
              ) : (
                <>
                  <span className={styles.link}>
                    {item.title}
                  </span>
                  <ul className={styles.submenu}>
                    {item.subItems?.map(subItem => (
                      <li key={subItem.title}>
                        <Link to={subItem.url} onClick={() => onLinkClick()} className={styles.link}>
                          {subItem.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </li>
          ))}
        </ul>

        <div className={styles.contactIcons}>
          <a href="tel:00420724946065" title="Zavolat">
            <i className="far fa-phone-rotary" />
          </a>
          <a href="mailto:info@ladema.cz" title="Poslat mail">
            <i className="far fa-envelope" />
          </a>
        </div>
      </div>
    </nav>
  );
};

Nav.defaultProps = {
  light: false
}

export default Nav;
