import * as React from "react";
import * as styles from "./footer.module.scss";
import {StaticImage} from "gatsby-plugin-image";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <StaticImage src="../../images/logo_white.png"
                     alt="logo"
                     className={styles.logoWrapper}
                     objectFit={"contain"}
        />
        <div>
          <h4>... na rozhodnutí záleží</h4>
          <small>2021 &copy; ladema.cz | Stránky zhotovil <a href="https://ledl.dev" target="_blank" rel="noreferrer">Martin Lédl</a></small>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
