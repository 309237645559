export const navItems = [
  {
    title: "Domů",
    url: "/",
    subItems: null,
  },
  {
    title: "Služby",
    url: "/sluzby",
    subItems: [
      {
        title: "Fyzické osoby",
        url: "/sluzby/fyzicke-osoby"
      },
      {
        title: "Společnosti",
        url: "/sluzby/spolecnosti"
      },
    ],
  },
  {
    title: "Realizace",
    url: "/#realizace",
    subItems: null,
  },
  {
    title: "Kontakt",
    url: "/kontakt",
    subItems: null,
  },
]
