import * as React from "react";
import * as styles from "./layout.module.scss";
import Footer from "../footer/footer";
import Header from "../header/header";
import classNames from "classnames";

const Layout = ({children, light, noFooter, noContactIcons}) => {
  return (
    <>
      <Header light={light} />

      {!noContactIcons && (
        <div className={styles.contactIconsWrapper}>
          <div className={classNames({
            [styles.contactIcons]: true,
            [styles.light]: light
          })}>
            <div className={styles.longLine} />
            <a href="mailto:info@ladema.cz" title="Poslat mail">
              <i className="fal fa-envelope" />
            </a>
            <div className={styles.shortLine} />
            <a href="tel:00420724946065" title="Zavolat">
              <i className="fal fa-phone-rotary" />
            </a>
          </div>
        </div>
      )}

      <main className={styles.main}>
        {children}
      </main>
      {!noFooter && <Footer />}
    </>
  );
};

Layout.defaultProps = {
  isAtHome: false,
  noFooter: false,
}

export default Layout;
