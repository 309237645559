import * as React from 'react';
import {Helmet} from 'react-helmet';
import {useStaticQuery, graphql} from 'gatsby';

function Seo({description, lang, meta, title, allowIndexing, allowFollowing}) {
  const {site} = useStaticQuery(
    graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }
        }
    `
  );

  const defaultTitle = site.siteMetadata?.title;
  const showOnlyDefaultTitle = title.length === 0 && defaultTitle;
  const htmlTitle = showOnlyDefaultTitle ? defaultTitle : title;
  const titleTemplate = !showOnlyDefaultTitle ? `%s | ${defaultTitle}` : `%s`;

  const metaDescription = description || site.siteMetadata?.description;

  return (
    <Helmet
      htmlAttributes={{
        lang: lang,
      }}
      title={htmlTitle}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: htmlTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:locale',
          content: lang,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata?.author || '',
        },
        {
          name: 'twitter:title',
          content: htmlTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'robots',
          content:
            (allowIndexing ? 'index, ' : 'noindex, ') + (allowFollowing ? 'follow' : 'nofollow'),
        },
      ].concat(meta)}
    >
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
      <link
        href="https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400&family=Lora:wght@400;700&display=swap"
        rel="stylesheet"/>
    </Helmet>
  );
}

Seo.defaultProps = {
  lang: 'cs',
  meta: [],
  description: '',
  title: '',
  allowIndexing: true,
  allowFollowing: true,
};

export default Seo;
