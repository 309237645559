// extracted by mini-css-extract-plugin
export var nav = "nav-module--nav--1FM6s";
export var menuToggle = "nav-module--menu-toggle--Y8uoV";
export var hamburgerInner = "nav-module--hamburger-inner--3w1EU";
export var light = "nav-module--light--2Ac3J";
export var menuOpened = "nav-module--menu-opened--1zeeG";
export var menuList = "nav-module--menu-list--3VDVB";
export var flexContainer = "nav-module--flex-container--awZft";
export var menuItem = "nav-module--menu-item--1pdgQ";
export var hasSubmenu = "nav-module--hasSubmenu--3HryD";
export var link = "nav-module--link--3dKBr";
export var submenu = "nav-module--submenu--1bjNh";
export var contactIcons = "nav-module--contactIcons--E50tt";